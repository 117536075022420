export default {
  'slot1': "1628542676",
  'slot2': "3705909665",
  'slot3': "1770011467",
  'firebaseConfig': {
   apiKey: "AIzaSyCIE8AGWQ8eF6zBZA-ihFPuvrwbo5ZoVoU",
  authDomain: "news10-network.firebaseapp.com",
  projectId: "news10-network",
  storageBucket: "news10-network.appspot.com",
  messagingSenderId: "73177495975",
  appId: "1:73177495975:web:65206b949809ae716a9e82",
  measurementId: "G-57E16PENSY"
  }
}